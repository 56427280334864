import React, { useEffect, useState } from "react";
import image from "./assets/image.png";
import styles from "./page_banner.module.css";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import SearchIcon from "./assets/search_icon.png";
import { useLocation } from "react-router-dom";
export default function PageBanner({ text }) {
  const [isSearchResluts, setIsSearchResults] = useState(false);
  const {
    register,
    handleSubmit,
    rese,
    formState: { errors },
  } = useForm();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const onSubmit = (data) => {
    console.log(data);
  };

  const { pathname } = useLocation();

  return (
    <section
      className={`${styles.page_banner} flex justify-center items-center`}
    >
      <div className="content relative z-10">
        <h1 className="text-5xl font-semibold font-cairo text-white">{text}</h1>
      </div>

      {isSearchResluts && (
        <form
          className={`${styles.search_bar} flex justify-center items-center shadow-md`}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div
            className={`${styles.search_btn} h-[84px] w-[84px] flex items-center justify-center bg-[#FD5674] rounded-full`}
          >
            <img src={SearchIcon} alt="" />
          </div>
          <div className="form_container flex gap-[2.62rem] px-5">
            <div className="trip_type flex flex-col gap-3">
              <label htmlFor="type" className="font-cairo font-bold">
                {t("heroSearchForm.type")}
              </label>
              <select {...register("type")}>
                <option value="">{t("heroSearchForm.typePlaceholder")}</option>
              </select>
            </div>
            <div className={styles.devider} />
            <div className="trip_location flex flex-col gap-3">
              <label htmlFor="location" className="font-cairo font-bold">
                {t("heroSearchForm.location")}
              </label>
              <select {...register("location")}>
                <option value="">
                  {t("heroSearchForm.locationPlaceholder")}
                </option>
              </select>
            </div>
            <div className={styles.devider} />

            <div className="trip_price flex flex-col gap-3">
              {" "}
              <label htmlFor="price" className="font-cairo font-bold">
                {t("heroSearchForm.price")}
              </label>
              <input
                type="text"
                {...register("price")}
                placeholder={t("heroSearchForm.pricePlaceholder")}
              />
            </div>
          </div>
        </form>
      )}
    </section>
  );
}
