import React from "react";
import { Link } from "react-router-dom";
import useSignUpHook from "./Hooks/useSignUpHook";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

const SignIn = () => {
  const { loading, formik } = useSignUpHook();
  const { t } = useTranslation();

  return (
    <div className="my-20 flex justify-center items-center">
      <form
        className="shadow-md w-full md:w-2/3 lg:w-1/3 py-4 px-4 md:px-10 mx-5 text-center flex flex-col"
        onSubmit={formik.handleSubmit}
      >
        <img
          src={require("./assets/login.jpg")}
          className="mx-auto w-32 md:w-48"
          alt="Login Image"
        />
        <h1 className="mt-6 md:mt-10 text-2xl md:text-3xl mb-4">
          {t("signUp.pgaeOne.title")}
        </h1>
        <div className="flex flex-row md:flex-row items-center mb-8">
          <div className="bg-slate-300 py-2 px-1 w-8 text-center md:text-left">
            +
          </div>
          <input
            className="border h-9 py-2 px-2 mt-2 md:mt-0 w-14"
            type="text"
            placeholder="مفتاح الدوله"
            name={"country_code"}
            value={formik.values.country_code}
            onChange={formik.handleChange}
          />
          <input
            className="border w-full h-9 py-2 px-5 mt-2 md:mt-0 md:ml-2"
            type="text"
            placeholder="رقم تلفونك"
            name={"phone"}
            value={formik.values.phone}
            onChange={formik.handleChange}
          />
        </div>
        <div className="flex flex-row md:flex-row items-center mb-8">
          <input
            className="border w-full h-9 py-2 px-5 mt-2 md:mt-0 md:ml-2"
            type="password"
            placeholder="password"
            name={"password"}
            value={formik.values.password}
            onChange={formik.handleChange}
          />
        </div>
        <div className="flex flex-row md:flex-row items-center mb-8">
          <input
            className="border w-full h-9 py-2 px-5 mt-2 md:mt-0 md:ml-2"
            type="password"
            placeholder="Re-type Password"
            name={"password_confirmation"}
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
          />
        </div>
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          disabled={loading}
        >
          {loading ? (
            <CircularProgress style={{ width: "20px", height: "20px" }} />
          ) : (
            t("signUp.pgaeOne.btnSubmet")
          )}
        </button>
        <Link to="/otp" className="text-blue-500 hover:text-blue-700 mt-4">
          {" "}
          {t("signUp.pgaeOne.loginBtn")}{" "}
        </Link>
      </form>
    </div>
  );
};

export default SignIn;
