import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enJSON from "./locale/en.json";
import arJSON from "./locale/ar.json";
import deJSON from "./locale/de.json";
import ruJSON from "./locale/ru.json";
import csJSON from "./locale/cs.json";
import plJSON from "./locale/pl.json";
 
i18n.use(LanguageDetector)
  .use(initReactI18next)
  .init({resources: {
      en: { translation: enJSON },
      ar: { translation: arJSON },
      de: { translation: deJSON },
      ru: { translation: ruJSON },
      pl: { translation: plJSON },
      cs: { translation: csJSON },
    },
    fallbackLng: "en",
    detection: {
      order: ["localStorage", "path", "navigator"],
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage"],
    },
    react: {
      useSuspense: false,
    },
  });

// Handle RTL languages and set the language direction
i18n.on("languageChanged", (lng) => {
  if (lng === "ar") {
    document.body.setAttribute("dir", "rtl");
  } else {
    document.body.setAttribute("dir", "ltr");
  }
});

export const changeLanguage = (lng) => {
  i18n.changeLanguage(lng).then(() => {
    if (lng === "ar") {
      document.body.setAttribute("dir", "rtl");
    } else {
      document.body.setAttribute("dir", "ltr");
    }
  });
};

export default i18n;