import axios from "axios";
import React, { useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { Notyf } from "notyf";
import { base_url } from "../../../utils/baseLink";

const useSignUpHook = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const notyf = new Notyf({
    position: { 
      x: 'right',
      y: 'top'  
    },
    duration: 7000 
  });

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await axios.post(`https://alrmoz.com/marsa_back/public/api/mobile/auth/register-user-page1`, values);
      const phone = response.data.data.phone;
      window.localStorage.setItem("marsaPhone", phone);

      const codeMarsa = response.data.data.code;
      window.localStorage.setItem("codeMarsa", codeMarsa);
      
      navigate(`/${base_url}/authentication-signup/otp`);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        notyf.error('Make sure you entered the phone number correctly');
      }
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      phone: "",
      country_code: "20",
      password: "",
      password_confirmation: ""
    },
    onSubmit: handleSubmit,
  });

  return { loading, formik };
};

export default useSignUpHook;