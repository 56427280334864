import React, { useEffect, useState } from "react";
import BreadCrumbs from "../../Components/BreadCrumbs/BreadCrumbs";
import { useTranslation } from "react-i18next";
import shareIcon from "./assets/solar_share-broken.png";
import mapIcon from "../../Components/TripCard/assets/map_logo.png";
import leftArrow from "./assets/left_arrow.svg";
import { FormControl, Input } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";
import { base_api } from "../../utils/apis";
import { isLogedIn } from "../../utils/token";
import Swal from "sweetalert2";

export default function ShowTrip() {
  const [selectedImage, setSelectedImage] = useState(0);
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const [information, setInformation] = useState([]);
  const [images, setImages] = useState([]);
  const [additionalData, setAdditionalData] = useState([]);
  const [serveses, setServeses] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [stepCompleted, setStepCompleted] = useState(false);

  const getInfo = async () => {
    try {
      const response = await axios.get(
        `${base_api}/${i18n.language}/api/mobile/tours/get-tour/${id}`
      );
      setInformation(response.data.data);
      setImages(response.data.data.photos);
      setAdditionalData(response.data.data.additional_data);
      setServeses(response.data.data.services);
    } catch (error) {
      console.log(error);
    }
  };

  // const [startTourDate, setStartTourDate] = useState("");
  // const [visitorsNumber, setVisitorsNumber] = useState(0);
  // const [childrenNumber, setChildrenNumber] = useState(0);

  // const handleSubmit = async () => {
  //   const token = isLogedIn();
  //   if (!token) {
  //     Swal.fire({
  //       title: "Not Logged In",
  //       text: "Please log in to proceed with your order.",
  //       icon: "warning",
  //     });
  //     return;
  //   }
  //   try {
  //     const res = await axios.post(
  //       `${base_api}/${i18n.language}/api/mobile/orders/store`,
  //       {
  //         start_tour_date: startTourDate,
  //         vistiors_number: visitorsNumber,
  //         children_number: childrenNumber,
  //         tour_id: id,
  //         payment_mode: paymentMethod,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const orderID = res.data.data.id;
  //     window.localStorage.setItem("orderID_to_payment", orderID);
  //     if (paymentMethod !== "cash") {
  //       handlepayment();
  //     }
  //   } catch (error) {
  //     console.error("Error submitting order:", error);
  //     Swal.fire({
  //       title: "Error",
  //       text: "Please check your order data and try again 😢🤦‍♀️",
  //       icon: "error",
  //     });
  //   }
  // };

  const [Booking_name, setBooking_name] = useState("");
  const [Booking_phone, setBooking_phone] = useState("");
  const [Booking_city, setBooking_city] = useState("");
  const [Booking_date, setBooking_date] = useState("");
  const [Bookingtour_name, setBookingtour_name] = useState("");
  const [Booking_hotel, setBooking_hotel] = useState("");
  const [Booking_countVisit, setBooking_countVisit] = useState("");
  const [Booking_notes, setBooking_notes] = useState("");
  const [Type_method, setType_method] = useState("");

  const handleBooking = async () => {
    try {
      await axios.post(
        `https://alrmoz.com/marsa_back/public/api/mobile/create_booking?lang=${i18n.language}`,
        {
          name: Booking_name,
          phone: Booking_phone,
          city: Booking_city,
          notes: Booking_notes,
          hotel: Booking_hotel,
          date: Booking_date,
          countvisit: Booking_countVisit,
          type_method: Type_method,
          tour_name: information.name,
        },
      );
      setStepCompleted(true);
      Swal.fire({
        title: "done",
        text: "The trip has been successfully booked",
        icon: "success",
      });
      // if (paymentMethod !== "cash") {
      //   handlepayment();
      // }
    } catch (error) {
      console.error("Error submitting order:", error);
      Swal.fire({
        title: "Error",
        text: "Please check your all inputs order data and try again 😢🤦‍♀️",
        icon: "error",
      });
    }
  };

  // Request to payment API
  const handlepayment = async () => {
    const token = isLogedIn();
    if (!token) {
      Swal.fire({
        title: "Not Logged In",
        text: "Please log in to proceed with your order.",
        icon: "warning",
      });
      return;
    }
    const orderIDLocal = window.localStorage.getItem("orderID_to_payment");
    console.log("Stored Order ID:", orderIDLocal);
    try {
      const paymentRes = await axios.post(
        `https://alrmoz.com/marsa_back/public/api/mobile/payments/send-payment?lang=${i18n.language}`,
        {
          orderId: orderIDLocal,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(paymentRes);

      // Assuming paymentRes.data contains the payment URL
      if (paymentRes.data.data.url) {
        window.open(paymentRes.data.data.url, "_blank");
      } else {
        Swal.fire({
          title: "Payment Error",
          text: "There was an issue with initiating the payment 😢",
          icon: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInfo();
  }, [i18n.language]);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: document.title,
          url: window.location.href,
        });
        console.log("Successfully shared");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      console.log("Web Share API is not supported in your browser.");
    }
  };

  return (
    <main>
      <div className="container w-[90%] mx-auto pb-36 mt-10">
        <div className="page_header flex flex-col sm:flex-row justify-between py-5 font-cairo font-medium">
          <BreadCrumbs category={information.trip_name} categoryId={1} />
          <div className="share mt-4 sm:mt-0">
            <button className="flex gap-2" onClick={handleShare}>
              {information.trip_name}
              <img src={shareIcon} alt="" width={24} height={24} />
            </button>
          </div>
        </div>
        <div className="gallery flex flex-col md:flex-row gap-3 pb-10">
          <div className="current_img w-full md:w-[80%] rounded-[10px] overflow-hidden h-[320px] md:h-[560px]">
            <figure className="h-full w-full relative">
              <img
                src={images[selectedImage]}
                alt=""
                className="w-full h-full object-cover"
              />
            </figure>
          </div>
          <div className="others w-full md:w-[19%] h-[500px] flex md:flex-col gap-1 md:gap-4 justify-between">
            {images.map(
              (img, index) =>
                index < images.length && (
                  <figure
                    key={index}
                    className="h-[200px] md:h-[100px] rounded-[10px] overflow-hidden cursor-pointer"
                    onClick={() => setSelectedImage(index)}
                  >
                    <img
                      src={img}
                      style={{ height: "200px" }}
                      width={50}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </figure>
                )
            )}
          </div>
        </div>

        <div className="info flex flex-col md:flex-row gap-4">
          <div className="content w-full md:w-[70%] flex flex-col gap-4">
            <div className="trip_name border-[0.7px] flex flex-col sm:flex-row justify-between items-center border-[rgb(186,192,202,20%)] rounded-[10px] px-6">
              <div className="right text-center sm:text-left">
                <h3 className="font-bold text-xl py-3 font-cairo">
                  {information.name}
                </h3>
                <p className="flex items-center justify-center sm:justify-start gap-2 py-3">
                  <img src={mapIcon} alt="" />
                  <span className="text-[#777891]">
                    {information.country} {"."} {information.city} {"."}
                    {information.street}
                  </span>
                </p>
              </div>
              <h2 className="left flex flex-col items-center gap-2 font-cairo mt-4 sm:mt-0">
                <span className="text-[#777891] text-lg font-medium">
                  {t("detailsPage.duration")}
                </span>
                <span className="text-[#FD5674] text-2xl font-bold">{`${
                  information.duration
                } ${t("detailsPage.durationByDays")}`}</span>
              </h2>
            </div>
            <div className="desc border-[0.7px] border-[rgb(186,192,202,20%)] rounded-[10px] py-4 px-6">
              <div className="descContent flex flex-col justify-around gap-6 pb-5">
                <h2 className=" font-cairo font-semibold text-2xl">
                  {" "}
                  {t("detailsPage.desc")}{" "}
                </h2>
                <div className="devider bg-[rgb(226,226,226,40%)] h-[1px] w-full"></div>
                <p className="font-medium font-cairo">
                  {" "}
                  {information.description}{" "}
                </p>
                <div className="devider bg-[rgb(226,226,226,40%)] h-[1px] w-full"></div>
              </div>
              <div className="servicesSection flex flex-col justify-around gap-6 pb-5">
                <div className="title">
                  <h2 className=" font-cairo font-semibold text-2xl">
                    {t("detailsPage.services")}
                  </h2>
                </div>
                <div className="devider bg-[rgb(226,226,226,40%)] h-[1px] w-full"></div>
                <div className="services flex flex-wrap md:flex-nowrap gap-10 md:gap-20">
                  {Array.isArray(serveses) && serveses.length > 0 ? (
                    serveses.map((item, index) => (
                      <div
                        key={index}
                        className="service flex items-center gap-4"
                      >
                        <img src={leftArrow} alt="" />
                        <h4 className="font-cairo font-medium ">{item}</h4>
                      </div>
                    ))
                  ) : (
                    <div className="service flex items-center gap-4">
                      <img src={leftArrow} alt="" />
                      <h4 className="font-cairo font-medium ">
                        No services available
                      </h4>
                    </div>
                  )}
                </div>
                <div className="devider bg-[rgb(226,226,226,40%)] h-[1px] w-full"></div>
              </div>

              <div className="rulesContent flex flex-col justify-around gap-6 pb-5">
                <div className="title">
                  <h2 className=" font-cairo font-semibold text-2xl">
                    {t("detailsPage.rules")}
                  </h2>
                </div>
                <div className="devider bg-[rgb(226,226,226,40%)] h-[1px] w-full"></div>
                <div className="rules">
                  <ul className="flex flex-col gap-4">
                    {additionalData === 0 ? (
                      <li className="flex items-center gap-4 font-cairo font-medium text-[#777891]"></li>
                    ) : Array.isArray(additionalData) &&
                      additionalData.length > 0 ? (
                      additionalData.map((item, index) => (
                        <li
                          key={index}
                          className="flex items-center gap-4 font-cairo font-medium text-[#777891]"
                        >
                          <img src={leftArrow} alt="" /> {item}
                        </li>
                      ))
                    ) : (
                      <li className="flex items-center gap-4 font-cairo font-medium text-[#777891]">
                        {" "}
                        <img src={leftArrow} alt="" /> {additionalData}{" "}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="cost flex flex-col gap-6 w-full md:w-[30%] border-[0.7px] border-[rgb(186,192,202,20%)] rounded-[10px] p-4">
            {/* make Booking */}
            <div>
              <h2 className="left flex  items-center justify-between font-cairo">
                <span className="text-[#777891] text-lg font-medium">
                  {t("detailsPage.cost")}
                </span>
                <span className="text-[#FD5674] text-3xl font-bold">
                  {information.price} <span className="text-xs mx-2">EUR</span>
                </span>
              </h2>
              <h5 className="text-lg font-semibold text-[#FD5674] flex items-center gap-2">
                <span
                  className="text-xl text-white bg-[#FD5674] w-10 h-10 rounded-full flex justify-center items-center"
                  style={{ lineHeight: "0" }}
                >
                  1
                </span>
                <span>Booking now</span>
              </h5>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleBooking();
                }}
                style={{ width: "100%", marginBottom: "16px" }}
              >
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>{t("user name")}</label>
                  <input
                    type="text"
                    name="Booking_name"
                    value={Booking_name} // Assuming userName state is defined
                    onChange={(e) => setBooking_name(e.target.value)}
                    placeholder="Type user name here"
                    className="border shadow rounded-md h-14 px-5 py-5 text-2xl"
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>{t("phone number")}</label>
                  <input
                    type="text"
                    name="Booking_phone"
                    value={Booking_phone} // Assuming phoneNumber state is defined
                    onChange={(e) => setBooking_phone(e.target.value)}
                    placeholder="Type phone number here"
                    className="border shadow rounded-md h-14 px-5 py-5 text-2xl"
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>{t("country")}</label>
                  <input
                    type="text"
                    name="Booking_city"
                    value={Booking_city} // Assuming country state is defined
                    onChange={(e) => setBooking_city(e.target.value)}
                    placeholder="Type country here"
                    className="border shadow rounded-md h-14 px-5 py-5 text-2xl"
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>{t("notes")}</label>
                  <input
                    type="text"
                    name="notes"
                    onChange={(e) => setBooking_notes(e.target.value)}
                    placeholder="Type notes here"
                    className="border shadow rounded-md h-14 px-5 py-5 text-2xl"
                  />
                </FormControl>


                {/* tours */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  {/* <label>{t("tour name")}</label> */}
                  <input
                    type="hidden"
                    name="tour_name"
                    value={Bookingtour_name}  
                    // onChange={(e) => setBookingtour_name(information.name)}
                    placeholder="Type tour name here"
                    className="border shadow rounded-md h-14 px-5 py-5 text-2xl"
                  />
                </FormControl>



                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>{t("hotel")}</label>
                  <input
                    type="text"
                    name="hotel"
                    onChange={(e) => setBooking_hotel(e.target.value)}
                    placeholder="Type hotel here"
                    className="border shadow rounded-md h-14 px-5 py-5 text-2xl"
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>{t("count visit")}</label>
                  <input
                    type="text"
                    name="countvisit"
                    onChange={(e) => setBooking_countVisit(e.target.value)}
                    placeholder="Type count visit here"
                    className="border shadow rounded-md h-14 px-5 py-5 text-2xl"
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>{t("date")}</label>
                  <input
                    type="date"
                    name="date"
                    onChange={(e) => setBooking_date(e.target.value)}
                    placeholder="Select date here"
                    className="border shadow rounded-md h-14 px-5 py-5 text-2xl"
                  />
                </FormControl>


                <FormControl fullWidth sx={{ mb: 2 }}>
                  <label>{t("Payment Method")}</label>
                  <div className="flex gap-4">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="paymentMethod"
                        value="cash"
                        onChange={(e) => setType_method(e.target.value)}
                        className="form-radio"
                      />
                      <span className="ml-2">{t("Cash")}</span>
                    </label>
                    {/* <label className="inline-flex items-center">
                      <input
                        type="radio"
                        name="paymentMethod"
                        value="card"
                        onChange={(e) => setType_method(e.target.value)}
                        className="form-radio"
                      />
                      <span className="ml-2">{"Visa"}</span>
                    </label> */}
                  </div>
                </FormControl>

                  <button
                    type="submit"
                    className="w-full py-2 px-4 rounded-lg font-cairo font-medium focus:outline-none focus:ring-2 focus:ring-opacity-50 bg-[#FD5674] text-white hover:bg-[#e44d6a] focus:ring-[#e44d6a]"
                  >
                    Booking now
                  </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
