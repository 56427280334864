import React, { useEffect, useState } from "react";
import SectionTitle from "../../../Components/SectionTitle/SectionTitle";
import styles from "./home_limited.module.css";
import axios from "axios";
import { base_api } from "../../../utils/apis";
import heart from "./assets/heart.png";
import filledHeart from "./assets/outline-heart.png";
import mapIcon from "./assets/map_logo.png";
import arrow from "./assets/solar_round-arrow-left-up-bold.png";
import { Link, useNavigate } from "react-router-dom";
import { base_url } from "../../../utils/baseLink";
import { isLogedIn } from "../../../utils/token";
import { Formik } from "formik";

export default function HomeLimitedOffer({ useTranslation }) {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { i18n } = useTranslation();
  const [cards, setCards] = useState([]);
  const token = isLogedIn();
  const navigate = useNavigate();

  const fetchTrips = async () => {
    try {
      const response = await axios.get(
        `${base_api}/${i18n.language}/api/mobile/home/get-limited-offers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCards(response.data.data);
      console.log(response.data.data);
    } catch (error) {
      console.error("Error fetching trips:", error);
    }
  };

  useEffect(() => {
    fetchTrips();
  }, [i18n.language]);

  const handleToggleFavorite = async (offer_id, index) => {
    try {
      const token = isLogedIn();
      if (!token) {
        console.error("No token found. User is not logged in.");
        return;
      }
      const response = await axios.post(
        `${base_api}/${i18n.language}/api/mobile/offers/toggle-favouriteOffer/${offer_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.data);

      const updatedCards = [...cards];
      updatedCards[index].is_favourite = response.data.data.is_favourite;
      setCards(updatedCards);
    } catch (error) {
      console.error("Error toggling favorite:", error);
    }
  };

  return (
    <section
      className={`${styles.limited_container} ${
        language === "en" ? styles.backg_right : styles.backg_left
      }`}
    >
      <div className="container mb-10 py-10 px-10 mx-auto">
        <SectionTitle
          title={t("homeSections.homeLimitedOffer.title")}
          desc={t("homeSections.homeLimitedOffer.desc")}
          useTranslation={useTranslation}
        />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-center my-10">
          {cards.map((item, index) => (
            <div
              className="card bg-white rounded-[10px] overflow-hidden flex flex-col font-cairo font-medium border border-1"
              style={{ height: "500px" }}
              key={item.tour_id}
            >
              <figure className="h-[60%] relative">
                <img
                  src={item.photo}
                  alt=""
                  loading="lazy"
                  style={{ height: "100%", width: "100%", cursor: "pointer" }}
                  onClick={() => {
                    navigate(`/${base_url}/tripInfo/${item.tour_id}`);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                />
                {token && (
                  <Formik
                    initialValues={{}}
                    onSubmit={() => handleToggleFavorite(item.offer_id, index)}
                  >
                    {({ handleSubmit, isSubmitting }) => (
                      <form onSubmit={handleSubmit}>
                        <button
                          type="submit"
                          className="absolute top-4 left-4 p-3 bg-white shadow-md rounded-full"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <div className="loaderFavu"></div>
                          ) : (
                            <img
                              src={item.is_favourite ? heart : filledHeart}
                              alt={
                                item.is_favourite
                                  ? "Filled Heart"
                                  : "Empty Heart"
                              }
                              width={20}
                              height={20}
                              loading="lazy"
                            />
                          )}
                        </button>
                      </form>
                    )}
                  </Formik>
                )}
              </figure>
              <div
                className="card_info px-3 cursor-pointer"
                onClick={() => {
                  navigate(`/${base_url}/tripInfo/${item.tour_id}`);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <p className="flex items-center gap-2 py-3">
                  <img src={mapIcon} alt="" loading="lazy" />
                  <span className="text-[#777891]">
                    {item.country} . {item.city}
                  </span>
                </p>
                <h3 className="font-bold text-xl truncate py-3">
                  {item.offer_name}
                </h3>
              </div>
              <div
                className="price flex justify-between items-center px-3 border border-b-0 border-l-0 border-r-0 py-2 border-[#E2E2E2]"
                onClick={() => {
                  navigate(`/${base_url}/tripInfo/${item.tour_id}`);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <div className="right flex flex-col gap-2 w-[75%]">
                  <p className="text-[#777891] text-sm">{"للفرد الواحد"}</p>
                  <h2 className="flex items-start justify-start text-4xl font-bold w-full text-[#FD5674]">
                    {item.offer_price_value}
                    <span className="mx-2 font-semibold text-sm py-1">EUR</span>
                  </h2>
                </div>
                <div className="left w-[25%] h-full flex justify-end items-center">
                  <Link
                    to={`/${base_url}/tripInfo/${item.tour_id}`}
                    className=""
                  >
                    <img
                      className="bg-[#FD5674] rounded-full p-2 "
                      src={arrow}
                      alt=""
                      loading="lazy"
                    />
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
