import React from "react";
import useOTPHook from "../Hooks/useOTPHook";
import { CircularProgress } from "@mui/material";

const OTP = () => {
  const { formik, loading } = useOTPHook();

  const code = localStorage.getItem("codeMarsa");

  return (
    <div className="my-20 flex justify-center items-center">
      <form
        className="shadow-lg pt-5 pb-2 px-4 flex flex-col justify-center items-center"
        onSubmit={formik.handleSubmit}
      >
        <img
          className="mb-10"
          width={150}
          src={require("../assets/login.jpg")}
          alt=""
        />
        <h1 className="text-2xl mb-5" style={{ fontWeight: "bold" }}>
          {" "}
          "<span style={{ color: "red" }}>{code}</span>" كود التحقق الخاص بك هو{" "}
        </h1>
        {/* <p className="mb-5 text-slate-400">  </p> */}
        <div className="form w-full">
          <div className="flex flex-col w-full mb-4">
            <input
              className="border w-full h-10 rounded-lg shadow-md px-5"
              type="text"
              id="otp"
              maxLength={4}
              name={"code"}
              value={formik.values.code}
              onChange={formik.handleChange}
            />
          </div>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 w-full rounded"
            disabled={loading}
          >
            {loading ? (
              <CircularProgress style={{ width: "20px", height: "20px" }} />
            ) : (
              "الدخول"
            )}
          </button>
          {/* <p onClick={handleResendCode} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
        {resending ? 'جاري الإرسال...' : 'اعاده الارسال؟'}
      </p> */}
        </div>
      </form>
    </div>
  );
};

export default OTP;
