import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import React from "react";
import { Link } from "react-router-dom";
import useLoginHook from "./Hook/useLoginHook";
import { base_url } from "../../utils/baseLink";
import { t } from "i18next";

const Login = ({ open, onClose }) => {
  const { formik } = useLoginHook();

  return (
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title" className="text-center font-bold">
          {t("login.title")}
        </DialogTitle>
        <DialogContent className="form-login-data flex flex-col gap-5 p-4 sm:p-6">
          <form
            onSubmit={async (event) => {
              event.preventDefault();
              await formik.handleSubmit(event);
              if (formik.isValid && !formik.isSubmitting) {
                onClose();
              }
            }}
            className="flex flex-col gap-4"
          >
            <input
              className="border text-base px-4 py-2 rounded-lg border-red-300 w-full focus:outline-none focus:ring-2 focus:ring-red-500"
              placeholder={t("login.login")}
              type="text"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
            <input
              className="border text-base px-4 py-2 rounded-lg border-red-300 w-full focus:outline-none focus:ring-2 focus:ring-red-500"
              placeholder={t("login.pass")}
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            <Button
              className="py-3 w-full text-lg"
              style={{ backgroundColor: "#02EF9D", color: "#000" }}
              type="submit"
              disabled={formik.isSubmitting}
            >
              {t("login.title")}
            </Button>
          </form>
          <div className="privacy text-center flex items-center gap-1 justify-center text-sm sm:text-base">
            <p>{t("login.footer")}</p>
            <div className="flex justify-center items-center gap-4 sm:gap-6 mt-2">
              <Link
                to={`/${base_url}/PrivacyPolicy`}
                className="text-blue-400 hover:underline"
              >
                {t("login.Policy")}
              </Link>
            </div>
          </div>
          <div className="btnForgitPassword text-center mt-4">
            <Link to={`/${base_url}/forgitPassword`} className="text-blue-400 hover:underline">
              {t("login.forginPass")}
            </Link>
          </div>
        </DialogContent>
      </Dialog>
    );
};

export default Login;