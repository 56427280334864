import React from "react";
import image from "./assets/image.png";
import overlay from "./assets/overlay.png";
import { Link, useNavigate } from "react-router-dom";
import { base_url } from "../../../utils/baseLink";
export default function HomeBestInnerTours({ useTranslation }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <section className="w-[90%] max-w-[1200px] mx-auto rounded-[30px] overflow-hidden my-10 py-5 h-full">
      <figure className="relative">
        <img className="w-full h-[220px]" src={image} alt="" />
        <img
          className="absolute top-0 left-0 w-full h-full"
          src={overlay}
          alt=""
        />
        <div className="info absolute inset-0 flex flex-col justify-center items-center text-white font-cairo px-4">
          <h2 className="text-4xl font-semibold mb-8 text-center lg:text-2xl lg:mb-6 md:text-xl md:mb-4 sm:mb-3">
            {t("homeSections.homeBestInnerTours.title")}
          </h2>
          <button
          onClick={() => {
            navigate(`/${base_url}/trips`);
            window.scrollTo(0, 0);
          }}
            className="text-lg border-2 border-white rounded-[40px] py-2 px-6 font-medium lg:text-base lg:py-1 lg:px-4 sm:text-sm sm:py-1 sm:px-3"
          >
            {t("homeSections.homeBestInnerTours.btn")}
          </button>
        </div>
      </figure>
    </section>
  );
}
