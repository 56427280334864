import React, { useEffect, useState } from "react";
import SectionTitle from "../../../Components/SectionTitle/SectionTitle";
import axios from "axios";
import { base_api } from "../../../utils/apis";
import { Link, useNavigate } from "react-router-dom";
import heart from "./assets/heart.png";
import filledHeart from "./assets/outline-heart.png";
import mapIcon from "./assets/map_logo.png";
import arrow from "./assets/solar_round-arrow-left-up-bold.png";
import { base_url } from "../../../utils/baseLink";
import { isLogedIn } from "../../../utils/token";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import { Formik } from "formik";
import "./toggleFavuLoader.css";

export default function HomeBestTrips({ useTranslation }) {
  const token = isLogedIn();
  const { t, i18n } = useTranslation();
  const [cards, setCards] = useState([]);
  const navigateTo = useNavigate();

  useEffect(() => {
    const fetchTrips = async () => {
      try {
        let response;
        try {
          response = await axios.get(
            `${base_api}/${i18n.language}/api/mobile/home/get-favourite-tours`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCards(response.data.data);
        } catch (error) {
          console.error("Error fetching favourite tours:", error);
          response = await axios.get(
            `${base_api}/${i18n.language}/api/mobile/home/get-public-tours`
          );
          setCards(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching trips:", error);
      }
    };
    fetchTrips();
  }, [i18n.language, token]);

  const handleToggleFavorite = async (id, index) => {
    try {
      if (!token) {
        console.error("No token found. User is not logged in.");
        return;
      }
      const response = await axios.post(
        `${base_api}/${i18n.language}/api/mobile/tours/toggle-favouriteTour/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.data);

      const updatedCards = [...cards];
      updatedCards[index].is_favourite = response.data.data.is_favourite;
      setCards(updatedCards);
    } catch (error) {
      console.error("Error toggling favorite:", error);
    }
  };

  return (
    <section className="pb-6 mx-auto w-[90%]">
      <SectionTitle
        title={t("homeSections.homeBestTrips.title")}
        desc={t("homeSections.homeBestTrips.desc")}
        link=""
        useTranslation={useTranslation}
      />
      <div className="hidden md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 justify-center my-10">
        {cards.map((item, index) => (
          <div
            className="card bg-white rounded-[10px] overflow-hidden flex flex-col font-cairo font-medium border border-1 cursor-pointer"
            key={index}
            style={{ height: "500px" }}
          >
            <figure className="h-[60%] relative">
              <img
                src={item.photo}
                alt=""
                style={{ height: "100%", width: "100%" }}
                onClick={() => {
                  navigateTo(`/${base_url}/tripInfo/${item.id}`);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              />
              {token && (
                <Formik
                  initialValues={{}}
                  onSubmit={() => handleToggleFavorite(item.id, index)}
                >
                  {({ handleSubmit, isSubmitting }) => (
                    <form onSubmit={handleSubmit}>
                      <button
                        type="submit"
                        className="absolute top-4 left-4 p-3 bg-white shadow-md rounded-full"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <div className="loaderFavu"></div>
                        ) : (
                          <img
                            src={
                              item.is_favourite === true ? heart : filledHeart
                            }
                            alt={
                              item.is_favourite ? "Filled Heart" : "Empty Heart"
                            }
                            width={20}
                            height={20}
                          />
                        )}
                      </button>
                    </form>
                  )}
                </Formik>
              )}
            </figure>
            <div
              className="card_info px-3"
              onClick={() => {
                navigateTo(`/${base_url}/tripInfo/${item.id}`);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <p className="flex items-center gap-2 py-3">
                <img src={mapIcon} alt="" />
                <span className="text-[#777891]">
                  {item.country} - {item.city} {"."} - {item.street}{" "}
                </span>
              </p>
              <h3 className="font-bold text-xl truncate py-3">{item.name}</h3>
            </div>
            <div
              className="price flex justify-between items-center px-3 border border-b-0 border-l-0 border-r-0 py-2 border-[#E2E2E2]"
              onClick={() => {
                navigateTo(`/${base_url}/tripInfo/${item.id}`);
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              <div className="right flex flex-col gap-2 w-[75%]">
                <p className="text-[#777891] text-sm">{"للفرد الواحد"}</p>
                <h2 className="flex items-start justify-start text-4xl font-bold w-full text-[#FD5674]">
                  {item.price}
                  <span className="mx-2 font-semibold text-sm py-1">EUR</span>
                </h2>
              </div>
              <div className="left w-[25%] h-full flex justify-end items-center">
                <Link to={`/${base_url}/tripInfo/${item.id}`}>
                  <img
                    className="bg-[#FD5674] rounded-full p-2 "
                    src={arrow}
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="md:hidden my-10">
        <Swiper
          spaceBetween={5}
          slidesPerView={1.3}
          pagination={{ clickable: true, el: ".swiper-pagination" }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          modules={[Pagination, Navigation]}
          className="mySwiper h-100 mt-5"
        >
          {cards.map((item, index) => (
            <SwiperSlide
              className="card bg-white rounded-[10px] overflow-hidden flex flex-col font-cairo font-medium border border-1"
              key={index}
              style={{ height: "500px" }}
            >
              <figure className="h-[60%] relative">
                <img
                  src={item.photo}
                  alt=""
                  style={{ height: "100%", width: "100%" }}
                  onClick={() => {
                    navigateTo(`/${base_url}/tripInfo/${item.id}`);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                />
                {token && (
                  <Formik
                    initialValues={{}}
                    onSubmit={() => handleToggleFavorite(item.id, index)}
                  >
                    {({ handleSubmit, isSubmitting }) => (
                      <form onSubmit={handleSubmit}>
                        <button
                          type="submit"
                          className="absolute top-4 left-4 p-3 bg-white shadow-md rounded-full"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <div className="loaderFavu"></div>
                          ) : (
                            <img
                              src={
                                item.is_favourite === true ? heart : filledHeart
                              }
                              alt={
                                item.is_favourite
                                  ? "Filled Heart"
                                  : "Empty Heart"
                              }
                              width={20}
                              height={20}
                            />
                          )}
                        </button>
                      </form>
                    )}
                  </Formik>
                )}
              </figure>
              <div
                className="card_info px-3"
                onClick={() => {
                  navigateTo(`/${base_url}/tripInfo/${item.id}`);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <p className="flex items-center gap-2 py-3">
                  <img src={mapIcon} alt="" />
                  <span className="text-[#777891]">
                    {item.country} - {item.city} {"."} - {item.street}{" "}
                  </span>
                </p>
                <h3 className="font-bold text-xl truncate py-3">{item.name}</h3>
              </div>
              <div
                className="price flex justify-between items-center px-3 border border-b-0 border-l-0 border-r-0 py-2 border-[#E2E2E2]"
                onClick={() => {
                  navigateTo(`/${base_url}/tripInfo/${item.id}`);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <div className="right flex flex-col gap-2 w-[75%]">
                  <p className="text-[#777891] text-sm">{"للفرد الواحد"}</p>
                  <h2 className="flex items-start justify-start text-4xl font-bold w-full text-[#FD5674]">
                    {item.price}{" "}
                    <span className="mx-2 font-semibold text-sm py-1">EUR</span>
                  </h2>
                </div>
                <div className="left w-[25%] h-full flex justify-end items-center">
                  <Link to={`/${base_url}/tripInfo/${item.id}`} className="">
                    <img
                      className="bg-[#FD5674] rounded-full p-2 "
                      src={arrow}
                      alt=""
                    />
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <div className="swiper-pagination"></div>
        </Swiper>
      </div>
    </section>
  );
}
